table,
th,
td {
  width: 100%;
  border-collapse: collapse;
}

.max-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

.dark-green-bg {
  background: #1f5760;
  color: #fabe47;
}

.orange-bg {
  background: #fabe47;
  color: #333;
}

.bold {
  font-weight: bold;
}

.drop-shadow {
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.1);
}

.text-dark-green {
  color: #1f5760;
}

.text-yellow {
  color: #fabe47;
}

.page-title {
  width: 100%;
  padding: 50px 0px;
  font-size: 2em;
}

.base-page {
  padding: 20px;
  width: 100%;

  .base-page-title {
    background-color: #fff;
    color: #444;
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    text-align: left;
  }

  .base-page-content {
    background-color: #fff;
    color: #444;
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    min-height: calc(100vh - 150px);
    position: relative;

    .base-page-filter {
      padding: 0px;
      text-align: left;
      margin-bottom: 10px;
    }

    .base-page-table {
      padding: 0px;
      overflow: auto;

      .datagrid-height {
        height: calc(100vh - 230px);
      }
    }
  }
}
