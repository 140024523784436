.change-password-form {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  padding-top: 50px;
  overflow: auto;
  padding-bottom: 50px;

  .form-box {
    width: 95%;
    max-width: 800px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #eee;
    box-shadow: 0 5px 5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;

    .form-title {
      padding: 8px 16px;
      text-align: left;
      font-weight: bold;
      font-size: 16px;
      color: #444;
      border-bottom: 1px solid #ddd;
    }

    .form-content {
      padding: 16px 24px;

      .preview-image-container {
        img {
          height: 200px;
        }
      }

      .form-field {
        margin-bottom: 8px;

        .form-label {
          text-align: left;
          font-size: 14px;
        }
      }
      .alert {
        text-align: left;
        font-size: 12px;
        color: #dd0000;
        padding-left: 8px;
        margin-top: 2px;
      }
      .ck-editor__editable {
        min-height: 350px;
      }
    }
    .form-footer {
      padding: 8px 16px;
      text-align: right;
      border-top: 1px solid #ddd;

      button {
        margin-left: 20px;
      }
    }
  }
}
