.login-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("../../../assets/images/login-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .login-box {
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 500px;
    padding: 20px;

    .login-title {
      font-size: 18px;
      color: #fff;
      text-align: center;
      margin-bottom: 20px;
    }

    .login-field {
      margin-bottom: 15px;

      .login-label {
        text-align: left;
        color: #fff;
      }
      .login-input {
        text-align: left;
        margin-bottom: 8px;

        input {
          width: 100%;
          padding: 8px 16px;
          border-radius: 16px;
          background-color: #fff;
          border: 1px solid #666;
        }
      }

      .alert {
        font-size: 12px;
        color: #dd0000;
        margin-top: 4px;
        text-align: left;
        padding-left: 16px;
      }
    }
    .login-button {
      button {
        width: 100%;
        padding: 8px 16px;
        font-size: 14px;
        background-color: #0d6efd;
        color: #fff;
        border: 1px solid #0d6efd;
        border-radius: 16px;
      }
      button:hover {
        cursor: pointer;
        background-color: #287dfa;
      }
      button:active {
        background-color: #0f60d6;
      }
    }
  }
}
