.base-layout {
  display: flex;
  height: 100vh;
  padding: 0px;

  .navigation-container {
    width: 300px;
    background: #023047;
    transition: width 0.7s ease 0s;
    padding-top: 50px;

    .menu-default {
      margin: 15px 20px;
      padding: 4px 16px;
      text-align: left;
      color: #eee;
    }
    .menu-default:hover {
      color: #ffbe0b;
      cursor: pointer;
    }
    .menu-active {
      margin: 15px 20px;
      padding: 6px 16px;
      text-align: left;
      color: #444;
      background-color: #ffbe0b;
      border-radius: 12px;
    }
  }

  .navigation-close {
    width: 0px !important;
  }

  .navigation-floating-overlay {
    display: none;
    transition: all 0.5s ease 1s;

    .navigation-floating-container {
      width: 300px;
      background: #023047;
      height: 100vh;
      padding-top: 45px;
      position: relative;
      transition: width 0.7s ease 0s;

      .icon-close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #eee;
      }

      .menu-default {
        margin: 15px 20px;
        padding: 4px 16px;
        text-align: left;
        color: #eee;
        position: inherit;
      }
      .menu-default:hover {
        color: #ffbe0b;
        cursor: pointer;
      }
      .menu-active {
        margin: 15px 20px;
        padding: 6px 16px;
        text-align: left;
        color: #444;
        background-color: #ffbe0b;
        border-radius: 12px;
      }
    }
  }
  .navigation-floating-overlay-close {
    display: none !important;

    .navigation-floating-container {
      width: 0px !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .navigation-container {
      display: none;
    }
    .navigation-floating-overlay {
      display: inline-block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 10;

      .navigation-floating-container {
        width: 300px;
        transition: width 0.7s ease 0s;
      }
    }

    .navigation-floating-overlay-close {
      display: none !important;
      //   transition: all 0.5s ease 1s;

      .navigation-floating-container {
        width: 0px !important;
      }
    }
  }

  .page-container {
    flex-grow: 1;
    width: 100%;
    background: #f2f2f2;

    .top-bar {
      display: flex;
      justify-content: start;
      align-items: center;
      height: 50px;
      background-color: #fff;
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
      padding-left: 16px;
      padding-right: 16px;

      .menu-icon-container {
        padding-top: 8px;
        margin: 0px;
      }

      .menu-icon-container:hover {
        cursor: pointer;
        color: #ffbe0b;
      }

      @media only screen and (min-width: 768px) {
        .menu-icon-container {
          display: none;
        }
      }

      .system-title {
        color: #555;
        font-size: 1.5em;
        font-weight: 500;
        margin-left: 20px;
        flex-grow: 1;
        text-align: left;
      }

      .avatar {
        height: 45px;
        width: 45px;
        position: relative;

        img {
          width: 100%;
          border-radius: 50%;
          overflow: hidden;
          border: 1px solid #bbb;
        }

        .user-menu-container {
          position: absolute;
          top: 50px;
          right: 0px;
          width: 200px;
          z-index: 50;
          background-color: #fff;
          border: 1px solid #ccc;
          box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.1);

          .user-menu {
            padding: 6px 12px;
            font-size: 14px;
            text-align: left;
          }
          .user-menu:hover {
            color: #ffbe0b;
          }

          .user-menu:last-child {
            border-top: 1px solid #ccc;
          }
        }
      }
      .avatar:hover {
        cursor: pointer;
      }
    }
    .page-content-container {
      display: flex;
      flex-grow: 1;
      height: calc(100vh - 50px);
      overflow-y: auto;
    }
  }
}
