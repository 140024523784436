.news-detail-page {
  width: 100%;
  background-color: #ddd;
  position: relative;

  .space-top {
    padding-top: 50px;
  }

  .news-detail-container {
    background-color: #fff;
    padding: 20px;

    .back-container {
      margin-bottom: 10px;
      text-align: left;
    }

    .img-container {
      margin-bottom: 20px;

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .news-detail-title {
      color: #fabe47;
      font-size: 18px;
      text-align: left;
      padding: 8px 16px;
      border-bottom: 1px solid #fabe47;
    }

    .news-detail-desc {
      text-align: left;
      padding: 8px 16px;
    }
  }
}
