.about-page {
  width: 100%;
  background-color: #ddd;
  position: relative;

  .space-top {
    padding-top: 50px;
  }

  .about-intro-container {
    background-color: #fff;
    padding: 40px 100px;

    .intro-img {
      width: 100%;
    }

    .intro-title {
      font-size: 1.5em;
      margin-bottom: 10px;
    }
    .intro-desc {
      color: #666;
    }
  }

  .vision-container {
    background-image: url("../../assets/images/office-banner.jpg");
    background-position: center;
    background-size: cover;
    padding: 60px 100px;
    position: relative;

    .overlay {
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }

    .grid-container {
      z-index: 20;
      position: inherit;
    }

    .vision-content {
      position: inherit;

      .vision-title {
        font-size: 1.5em;
      }
      .vision-desc {
        color: #fff;
      }
    }
  }

  .legal-container {
    padding: 60px 100px;
    position: relative;
    background-color: #fff;

    .grid-container {
      z-index: 20;
      position: inherit;

      .rental-item-image-container {
        padding: 16px;

        .preview-container {
          .rental-item-image {
            width: 100%;
            max-height: 250px;
            object-position: center;
            object-fit: contain;
          }
        }

        .thumbnail-container {
          display: flex;
          overflow: auto;
          border-top: 1px solid #ccc;
          padding: 4px;

          .thumbnail-img {
            width: 80px;
            height: 80px;
            object-fit: contain;
            object-position: center;
            margin-right: 4px;
            padding: 4px;
            border: 1px solid #ddd;
            border-radius: 8px;
          }
          .thumbnail-img:hover {
            cursor: pointer;
          }
        }
      }
    }

    .legal-content {
      position: inherit;

      .legal-title {
        font-size: 1.5em;
      }
      .legal-desc {
        color: #333;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .about-intro-container {
      padding: 30px 30px;
    }
    .vision-container {
      padding: 60px 30px;
    }

    .legal-container {
      padding: 60px 30px;
    }
  }
}
