.news-page {
  width: 100%;
  background-color: #ddd;
  position: relative;

  .space-top {
    padding-top: 50px;
  }

  .news-container {
    background-color: #fff;
    padding: 40px 50px;

    .noitem {
      height: 300px;
      color: #555;
      font-size: 14px;
    }

    .column-container {
      border: 1px solid #ccc;
      border-radius: 8px;
      overflow: hidden;
    }

    .column-container:hover {
      border: 1px solid #aaa;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    .news-img {
      height: 200px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .news-title {
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: left;
      font-size: 16px;
      font-weight: bold;
      padding-left: 8px;
      padding-right: 8px;
    }
    .news-desc {
      color: #333;
      text-align: justify;
      font-size: 14px;
      margin-bottom: 8px;
      line-height: 1.1rem;
      padding-left: 8px;
      padding-right: 8px;
    }
    .news-date {
      color: #888;
      text-align: left;
      font-size: 14px;
      padding-left: 8px;
      padding-right: 8px;
      margin-bottom: 10px;
    }

    .loadmore-container {
      margin-top: 20px;
      text-align: center;

      .btn-loadmore {
        border: 1px solid #0d6efd;
        border-radius: 8px;
        padding: 10px 20px;
        color: #0d6efd;
        background-color: #fff;
      }

      .btn-loadmore:hover {
        color: #fff;
        background-color: #0d6efd;
        cursor: pointer;
        box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
