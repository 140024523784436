.home-page {
  width: 100%;
  background-color: #ddd;

  .top-banner-container {
    background-image: url("../../assets/images/banner-bg-crop.jpg");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    img {
      width: 100%;
      padding: 0px;
    }

    .hidden-bg {
      opacity: 0;
    }

    .transbox {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: #000;
      opacity: 0.5;
      z-index: 5;
    }

    .top-banner-content-container {
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 100%;
      max-width: 1200px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }

    .top-banner-content {
      position: absolute;
      top: 50%;
      z-index: 10;
      transform: translateY(-40%);
      color: #fff;
      right: 50px;
      text-align: right;

      .slogan {
        font-size: 4em;
        text-align: right;
        color: #fabe47;
      }
      .small-intro {
        width: 100%;
        font-size: 1.5em;
        text-align: right;
        float: right;
        width: 80%;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .top-banner-container {
      height: 700px;

      .top-banner-content {
        .slogan {
          font-size: 3em;
        }
        .small-intro {
          font-size: 1.1em;
        }
      }
    }
  }

  @media only screen and (max-width: 479px) {
    .top-banner-container {
      height: 500px;

      .top-banner-content {
        .slogan {
          font-size: 2em;
        }
        .small-intro {
          font-size: 1em;
        }
      }
    }
  }

  .second-section-container {
    .field-padding {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .field-icon-container {
      padding: 20px;

      .field-icon {
        width: 100px;
      }
      .svg-icon {
        width: 100px;
        fill: #fabe47;
      }
      .svg-icon2 {
        width: 100px;
        fill: #333;
      }
    }

    .field-desc-container {
      padding: 15px;
      .field-title {
        font-size: 1.8em;
      }

      .field-desc {
        padding-right: 20px;
      }
    }
  }

  .service-section-container {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #fff;

    .service-section-title {
      font-size: 1.5em;
      color: #fabe47;
      margin-bottom: 6px;
    }
    .service-section-subtitle {
      font-size: 2em;
      color: #1f5760;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .service-section-content {
      padding: 20px 20px;

      .separator-container {
        background-color: #fff;

        .separator {
          height: 1px;
          width: 80%;
          margin: auto;
          border-bottom: 1px solid #ddd;
        }
      }

      .mechanic-container {
        background-color: #fff;
        padding: 40px 100px;

        .mechanic-title {
          font-size: 1.2em;
          margin-bottom: 10px;
        }
        .mechanic-desc {
          color: #666;
        }
      }

      @media only screen and (max-width: 700px) {
        .mechanic-container {
          padding: 30px 30px;
        }
      }

      .electric-container {
        background-color: #fff;
        padding: 40px 100px;

        .electric-title {
          font-size: 1.2em;
          margin-bottom: 10px;
        }
        .electric-desc {
          color: #666;
        }

        .electric-mobile {
          display: none;
        }
      }

      @media only screen and (max-width: 700px) {
        .electric-container {
          padding: 30px 30px;

          .electric-desktop {
            display: none;
          }

          .electric-mobile {
            display: flex;
          }
        }
      }

      .service-item-image-container {
        .preview-container {
          .service-item-image {
            width: 100%;
            max-height: 200px;
            object-position: center;
            object-fit: contain;
          }
        }

        .thumbnail-container {
          display: flex;
          overflow: auto;
          border-top: 1px solid #ccc;
          padding: 4px;

          .thumbnail-img {
            width: 80px;
            height: 80px;
            object-fit: contain;
            object-position: center;
            margin-right: 4px;
            padding: 4px;
            border: 1px solid #ddd;
            border-radius: 8px;
          }
          .thumbnail-img:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .sector-section-container {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #fff;

    .sector-section-title {
      font-size: 1.5em;
      color: #fabe47;
      margin-bottom: 6px;
    }
    .sector-section-subtitle {
      font-size: 2em;
      color: #1f5760;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .sector-section-content {
      padding: 40px;

      .sector-image-container {
        border: 1px solid #eee;
      }

      .sector-image-container:hover {
        border: 1px solid #fabe47;
        box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
      }

      .sector-image {
        position: relative;
        width: 100%;
        height: 250px;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .sector-title {
        text-align: left;
        font-size: 1.2em;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 20px;
      }
    }
  }

  .client-section-container {
    padding-top: 30px;
    padding-bottom: 40px;

    .client-section-title {
      font-size: 1.5em;
      color: #fabe47;
      margin-bottom: 12px;
    }

    .client-section-content {
      position: relative;
      padding-left: 20px;
      padding-right: 20px;

      .client-column {
        margin-bottom: 1em;
      }

      .img-container {
        height: 125px;
        line-height: 125px;
        background-color: #fff;
        border-radius: 20px;
        padding: 0px 10px;

        img {
          width: 100%;
          vertical-align: middle;
        }
      }
    }
  }

  .why-section-container {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #fff;

    .why-section-title {
      font-size: 1.5em;
      color: #fabe47;
      margin-bottom: 6px;
    }
    .why-section-subtitle {
      font-size: 2em;
      color: #1f5760;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .why-section-content {
      padding: 40px;

      .why-icon {
        text-align: center;
        img {
          width: 80px;
        }
      }

      .why-title {
        font-size: 1.4em;
        color: #fabe47;
      }

      .why-desc {
        font-size: 0.9em;
      }
    }
  }
}
