.rental-page {
  width: 100%;
  background-color: #ddd;
  position: relative;

  .space-top {
    padding-top: 50px;
  }

  .rental-container {
    background-color: #fff;
    padding: 40px 50px;

    .rental-category-container {
      width: 100%;
      overflow: auto;
      padding-bottom: 5px;

      .table-category {
        margin: auto;
        width: auto;
      }

      .column-category {
        padding-left: 5px;
        padding-right: 5px;
        width: 160px;
      }

      .rental-category {
        padding: 8px 16px;
        border: 1px solid #ddd;
        width: 150px;
        height: 165px;
        border-radius: 8px;

        img {
          width: 100%;
          height: 110px;
          object-fit: contain;
        }
      }
      .rental-category:hover {
        cursor: pointer;
      }
      .rental-category-active {
        border: 1px solid #fabe47;
        color: #fabe47;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
      }
    }
    .rental-item-container {
      margin-top: 30px;
      margin-bottom: 50px;
      padding-left: 50px;
      padding-right: 50px;

      .rental-item-empty {
        display: flex;
        width: 100%;
        height: 200px;
        align-items: center;
        justify-content: center;
      }

      .rental-item-grid {
        border-top: 1px solid #ddd;
        margin-top: 30px;
        align-items: flex-start;
      }

      .rental-item-grid:last-child {
        border-bottom: 1px solid #ddd;
      }

      .rental-item-image-container {
        padding: 16px;

        .preview-container {
          .rental-item-image {
            width: 100%;
            max-height: 200px;
            object-position: center;
            object-fit: contain;
          }
        }

        .thumbnail-container {
          display: flex;
          overflow: auto;
          border-top: 1px solid #ccc;
          padding: 4px;

          .thumbnail-img {
            width: 80px;
            height: 80px;
            object-fit: contain;
            object-position: center;
            margin-right: 4px;
            padding: 4px;
            border: 1px solid #ddd;
            border-radius: 8px;
          }
          .thumbnail-img:hover {
            cursor: pointer;
          }
        }
      }

      .rental-item-spec {
        vertical-align: top;
      }

      .rental-item-name {
        color: #fabe47;
        font-size: 16px;
        text-align: left;
      }
      .rental-item-category {
        color: #1f5760;
        font-size: 16px;
        text-align: left;
      }
      .rental-item-desc {
        text-align: left;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 767.98px) {
    .rental-container {
      background-color: #fff;
      padding: 40px 20px;

      .rental-item-container {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
