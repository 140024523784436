.component-profile-top-bar {
  height: 60px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 100;

  .top-bar-content {
    max-width: 1200px;
    margin: auto;
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 0px 20px;
    position: relative;

    .left-container {
      display: flex;
      align-items: center;

      .logo {
        width: 104px;
      }
      .logo:hover {
        cursor: pointer;
      }
    }

    .nav-container {
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: center;

      .nav-default {
        padding: 0px 20px;
        font-weight: medium;
        font-size: 16px;
      }
      .nav-default:hover {
        cursor: pointer;
        color: #fabe47;
      }

      .nav-active {
        padding: 0px 20px;
        font-weight: bold;
        font-size: 16px;
        color: #1f5760;
        border-bottom: 2px solid #1f5760;
      }
    }

    .right-container {
      display: none;
      align-items: center;
      width: 104px;
    }

    .mobile-menu-container {
      position: absolute;
      z-index: 20;
      top: 65px;
      left: 50%;
      width: 98%;
      background: #fff;
      transform: translateX(-50%);
      padding: 10px 0px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;

      .mobile-menu-default {
        padding: 10px 24px;
        text-align: right;
        color: #1f5760;
        font-weight: bold;
      }
      .mobile-menu-active {
        padding: 10px 24px;
        text-align: right;
        color: #fabe47;
        font-weight: bold;
      }
    }

    @media only screen and (max-width: 991px) {
      .nav-container {
        display: none;
      }

      .right-container {
        display: flex;
        justify-content: end;
      }
    }
  }
}
