.contact-page {
  width: 100%;
  background-color: #ddd;
  position: relative;

  .space-top {
    padding-top: 50px;
  }

  .contact-container {
    background-color: #fff;
    padding: 40px 100px;

    .contact-intro {
      margin-bottom: 20px;
      font-size: 18px;
      color: #333;
    }

    .grid-container {
      border: 1px solid #ddd;
    }

    .kontak-container {
      padding: 15px;

      div.kontak-field-container {
        margin: 10px;
        margin-bottom: 20px;
        border: 1px solid #888;
        padding: 20px 15px;

        .kontak-title {
          text-align: left;
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .kontak-desc {
          text-align: left;
          color: #eee;
          font-size: 14px;
        }
      }
    }

    .form-container {
      padding: 20px;
      background-color: #fabe47;

      .form-kontak-field {
        padding: 5px;
        text-align: left;
        input,
        textarea {
          border: 1px solid #eee;
          background: transparent;
          padding: 12px 16px;
          width: 100%;
          background-color: #fff;
        }

        button {
          border: 1px solid #0d6efd;
          background-color: #0d6efd;
          color: #fff;
          padding: 12px 16px;
        }
        button:hover {
          cursor: pointer;
          background-color: #287dfa;
        }
        button:active {
          background-color: #0f60d6;
        }
      }
    }
  }
}
