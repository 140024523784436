.client-page {
  width: 100%;
  background-color: #ddd;
  position: relative;

  .space-top {
    padding-top: 50px;
  }

  .client-section-content {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    min-height: calc(100vh - 500px);

    padding-top: 40px;
    padding-bottom: 40px;

    .client-content {
      width: 100%;
      max-width: 900px;
      margin: auto;

      .client-column {
        margin-bottom: 1em;
      }

      .img-container {
        height: 125px;
        line-height: 125px;
        background-color: #fff;
        border-radius: 20px;
        padding: 0px 10px;
        object-fit: contain;

        img {
          width: 100%;
          height: 100%;
          vertical-align: middle;
          object-fit: contain;
        }
      }
    }
  }
}
