.component-profile-footer {
  padding: 40px 50px;
  padding-bottom: 0px;

  .footer-content {
    padding-bottom: 10px;
  }

  .footer-section-container {
    .footer-section-title {
      border-bottom: 1px solid #fabe47;
      margin-bottom: 20px;
      font-weight: bold;
    }

    .footer-section-content {
      padding-right: 20px;

      .section-icon {
        vertical-align: top;
        width: 30px;
        text-align: center;
      }
      .section-content {
        vertical-align: top;
        color: #fff;
        padding-bottom: 15px;
        padding-left: 10px;

        span {
          font-size: 12px;
        }
      }

      ul {
        margin-left: 0px;
        padding-left: 20px;
      }

      li {
        text-align: left;
        margin-bottom: 5px;
      }

      a {
        color: #fabe47;
        text-decoration: none;
      }
    }

    .social-media-container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 5px;
    }
  }

  .copyright-container {
    border-top: 1px solid #fabe47;
    padding: 8px;
    text-align: right;
    font-size: 0.8em;

    span {
      color: #fff;
    }
    .link {
      text-decoration: none;
      color: #fabe47;
    }
    .link:hover {
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 991px) {
  .component-profile-footer {
    padding: 20px;
  }
}
