.company-page {
  .button-container {
    margin-top: 20px;
    text-align: right;

    button:first-child {
      margin-right: 10px;
    }
  }
  .ck-editor__editable_inline {
    height: 300px !important;
  }
  .preview-image-container {
    display: flex;
    overflow: auto;
    padding-top: 5px;
    padding-bottom: 5px;

    .img-box {
      margin-right: 10px;
      border-radius: 8px;
      border: 1px solid #ddd;
      overflow: hidden;
      position: relative;

      .close-container {
        position: absolute;
        top: 5px;
        right: 5px;
      }
      .close-container:hover {
        cursor: pointer;
      }

      img {
        height: 150px;
      }
    }
  }
}
