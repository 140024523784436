.service-page {
  width: 100%;
  background-color: #ddd;
  position: relative;

  .space-top {
    padding-top: 50px;
  }

  .service-intro-container {
    background-color: #fff;
    padding: 20px 100px;
    background-color: #c6e2f7;

    .service-grid {
      background-color: #c6e2f7;
    }

    .service-img {
      width: 100%;
    }

    .service-title {
      font-size: 1.5em;
      margin-bottom: 10px;
    }
    .service-desc {
      color: #333;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 400;
    }
  }

  @media only screen and (max-width: 900px) {
    .service-intro-container {
      background-color: #fff;
      padding: 30px 30px;
    }
  }

  .separator-container {
    background-color: #fff;

    .separator {
      height: 1px;
      width: 80%;
      margin: auto;
      border-bottom: 1px solid #ddd;
    }
  }

  .mechanic-container {
    background-color: #fff;
    padding: 40px 100px;

    li {
      margin-bottom: 20px;
    }

    .mechanic-img {
      width: 100%;
    }

    .mechanic-title {
      font-size: 1.2em;
      margin-bottom: 10px;
    }
    .mechanic-desc {
      color: #666;
    }
  }

  @media only screen and (max-width: 700px) {
    .mechanic-container {
      padding: 30px 30px;
    }
  }

  .electric-container {
    background-color: #fff;
    padding: 40px 100px;

    .electric-title {
      font-size: 1.2em;
      margin-bottom: 10px;
    }
    .electric-desc {
      color: #666;
    }

    .electric-mobile {
      display: none;
    }
  }

  @media only screen and (max-width: 700px) {
    .electric-container {
      padding: 30px 30px;

      .electric-desktop {
        display: none;
      }

      .electric-mobile {
        display: flex;
      }
    }
  }

  .service-item-image-container {
    padding: 16px;

    .preview-container {
      .service-item-image {
        width: 100%;
        max-height: 200px;
        object-position: center;
        object-fit: contain;
      }
    }

    .thumbnail-container {
      display: flex;
      overflow: auto;
      border-top: 1px solid #ccc;
      padding: 4px;

      .thumbnail-img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        object-position: center;
        margin-right: 4px;
        padding: 4px;
        border: 1px solid #ddd;
        border-radius: 8px;
      }
      .thumbnail-img:hover {
        cursor: pointer;
      }
    }
  }
}
